<template>
  <div>
    <page-title heading="Happy hour" show-previous-button subheading="Happy hour" :icon=icon></page-title>
    <div class="app-main__inner">
      <edit-happy-hour></edit-happy-hour>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import editHappyHour from "../../../components/erp/baseData/editHappyHour";

export default {
  components: {
    PageTitle,
    editHappyHour
  },

  data: () => ({
    icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
  })
}
</script>